@import "../../bower_components/material-design-lite/src/variables";
@import "../../bower_components/material-design-lite/src/mixins";

$select-text-font-size: 16px !default;
$select-text-width: 100% !default;
$select-text-padding: 4px !default;
$select-text-vertical-spacing: 20px !default;

$select-text-button-size: 32px !default;
$select-text-floating-label-fontsize: 12px !default;
$select-text-expandable-icon-top: 16px !default;

$select-text-background-color: transparent !default;
$select-text-label-color: unquote("rgba(#{$color-black}, 0.26)") !default;
$select-text-bottom-border-color: unquote("rgba(#{$color-black}, 0.12)") !default;
$select-text-highlight-color: unquote("rgb(#{$color-primary})") !default;
$select-text-disabled-color: $select-text-bottom-border-color !default;
$select-text-disabled-text-color: $select-text-label-color !default;
$select-text-error-color: unquote("rgb(222, 50, 38)") !default;

// The container for the whole component.
.mdl-selectfield {
  position: relative;
  font-size: $select-text-font-size;
  display: inline-block;
  box-sizing: border-box;
  width: 300px;
  max-width: 100%;
  margin: 0;
  padding: $select-text-vertical-spacing 0;

  // Align buttons, if used.
  & .mdl-button {
    position: absolute;
    bottom: $select-text-vertical-spacing;
  }
}

// Optional class to align right.
.mdl-selectfield--align-right {
  text-align: right;
}

// Optional class to display at full width.
.mdl-selectfield--full-width {
  width: 100%;
}

// Styling for the select element.
.mdl-selectfield__select {
  display: block;
  width: 100%;
  padding: $select-text-padding 0;
  margin: 0;
  color: inherit;
  background: 0 0;
  font-size: $select-text-font-size;
  text-align: left;
  color: inherit;
  border: none;
  border-bottom: 1px solid $select-text-bottom-border-color;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  .mdl-selectfield.is-focused & {
    outline: none;
  }

  .mdl-selectfield.is-invalid & {
    border-color: $select-text-error-color;
    box-shadow: none;
  }

  fieldset[disabled] .mdl-selectfield &, .mdl-selectfield.is-disabled & {
    background-color: transparent;
    border-bottom: 1px dotted $select-text-disabled-color;
    color: $select-text-disabled-text-color;
  }
}

// Styling for the label / floating label.
.mdl-selectfield__label {
  bottom: 0;
  color: $select-text-label-color;
  font-size: $select-text-font-size;
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  top: ($select-text-padding + $select-text-vertical-spacing);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;

  .mdl-selectfield.is-dirty & {
    visibility: hidden;
  }

  // Floating Label
  .mdl-selectfield--floating-label & {
    @include material-animation-default();
  }

  fieldset[disabled] .mdl-selectfield &,
  .mdl-selectfield.is-disabled.is-disabled & {
    color: $select-text-disabled-text-color;
  }

  .mdl-selectfield--floating-label.is-focused &,
  .mdl-selectfield--floating-label.is-dirty & {
    color: $select-text-highlight-color;
    font-size : $select-text-floating-label-fontsize;
    top: $select-text-vertical-spacing - ($select-text-floating-label-fontsize + $select-text-padding);
    visibility: visible;
  }

  .mdl-selectfield--floating-label.is-focused .mdl-selectfield__expandable-holder &,
  .mdl-selectfield--floating-label.is-dirty .mdl-selectfield__expandable-holder &{
    top: -($select-text-floating-label-fontsize + $select-text-padding);
  }

  .mdl-selectfield--floating-label.is-invalid & {
    color: $select-text-error-color;
    font-size: $select-text-floating-label-fontsize;
  }

  // The after label is the colored underline for the TextField.
  &:after {
    background-color: $select-text-highlight-color;
    bottom: $select-text-vertical-spacing;
    content: '';
    height: 2px;
    left: 45%;
    position: absolute;
    @include material-animation-default();
    visibility: hidden;
    width: 10px;
  }

  .mdl-selectfield.is-focused &:after {
    left: 0;
    visibility: visible;
    width: 100%;
  }

  .mdl-selectfield.is-invalid &:after {
    background-color: $select-text-error-color;
  }
}

// SelectField Error.
.mdl-selectfield__error {
  color: $select-text-error-color;
  position: absolute;
  font-size: $select-text-floating-label-fontsize;
  margin-top: 3px;
  visibility: hidden;
  display: block;

  .mdl-selectfield.is-invalid & {
    visibility: visible;
  }
}
